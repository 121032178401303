import { LanguageCode, useLocale } from '~/composables/useLocale'
import { MenuQueryDocument } from '../graphql/generated'
import { LanguageId, MenuLinkFieldsFragment, MainMenuFieldsFragment } from '~/api/graphql/generated'

const menus = ['main', 'meta-menu', 'footer', 'compliance-menu'] as const
export type MenuName = (typeof menus)[number]

export type ElementToFilter = MainMenuFieldsFragment & MenuLinkFieldsFragment & { links: ElementToFilter[] | null }

const filterLinksByLanguage = (element: ElementToFilter, language: LanguageCode): ElementToFilter => {
  const filteredElement: ElementToFilter = {
    ...element,
    links: [...(element?.links || [])]
      ?.map((link: ElementToFilter) => filterLinksByLanguage(link, language))
      .filter((link: ElementToFilter) => link.entity?.langcode?.value === language)
      .map((link: ElementToFilter) => {
        if (link.entity?.__typename === 'MenuLinkContentMain' && link.entity?.fieldIsShopPage && link.url) {
          return {
            ...link,
            url: {
              path: link.entity.fieldShopCategory,
            },
          }
        }
        return link
      }),
  }

  return filteredElement
}

export interface MenuItem {
  id: string
  url: string
  target: string
  title: string
  isActive: boolean
  items?: MenuItem[]
}

export type Menu = MenuItem[]

export const createItem = (data: ElementToFilter, path: string): MenuItem => ({
  id: `${(data.entity?.id || -1).toString()}-${data.entity?.langcode?.value} `,
  url: data.url?.path || '',
  target: data.attribute || '',
  title: data.label === '<notitle>' ? '‎' : data.label || '',
  isActive: data.url?.path === path,
  ...(data.links ? { items: data.links.map((item) => createItem(item, path)) } : {}),
})

export const useMenu = async (menuName: MenuName) => {
  const route = useRoute()
  const { clients } = useApollo()
  const { languageCode, languageCODE } = useLocale()

  const getVariables = () => ({
    menu: menuName,
    language: languageCODE.value as LanguageId,
  })

  const getMenu = async (lang: LanguageCode) => {
    const key = `menu-${menuName}-${lang}`

    const { data } = await useAsyncData(key, async () => {
      const { data } = await clients!.default.query({
        query: MenuQueryDocument,
        variables: getVariables(),
        fetchPolicy: 'no-cache',
      })

      return data.menuByName
    })

    if (!data.value) return { links: [] }
    return filterLinksByLanguage(data.value as ElementToFilter, lang)
  }

  const data = ref(await getMenu(languageCode.value))

  watch(languageCode, async (lang) => {
    data.value = await getMenu(lang)
  })

  const menu = computed(() => {
    if (!data.value || !data.value.links) return []
    return data.value.links.map((menuItem) => createItem(menuItem, route.path))
  })

  return { menu, data }
}
